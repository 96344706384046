import { AppAbilities, AppAbilityActions, AppAbilitySubjects } from '@abilities/app.ability';
import { ClientScopes } from '@abilities/clients/clients.scopes';
import { FileScopes } from '@abilities/files/files.scopes';
import { VendorScopes } from '@abilities/vendors/vendors.scopes';
import { WorkerScopes } from '@abilities/workers/workers.scopes';
import { FieldStateList } from '@dictionaries/field-state-list';
import { UserType } from '@dictionaries/user-types';
import {
	isCompanyIndependently as _isCompanyIndependently,
	isRoleIndependentlySuperAdmin as _isRoleIndependentlySuperAdmin,
} from '@indly/auth/helpers/is-independently-auth.helper';
import type {
	BaseUserCompanyWithAuthEmbeds,
	Company,
	CompanyWhitelabel,
	JurisdictionCountry,
	JurisdictionCountryState,
	Role,
	UserTokens,
	UserWithAuthEmbedsWithoutCompanies,
} from '@openapi/models';
import { setUser as setSentryUser } from '@sentry/nextjs';
import { filter, isNil, isString, maxBy } from 'lodash';
import { vanillaStore } from 'stores/vanilla.store';
import type { PartialDeep } from 'type-fest';
import { LOGIN_PAGE_PATH } from './constants/auth.constant';

// @TODO:
// import { RequestMethod } from 'libs/request/types/request.type';
// import type { BaseReturnableQueryParams } from '@openapi/models';
// import { createHmac } from 'crypto';

export const isLoggedIn = (): boolean => {
	// console.log(`[INFO] isLoggedIn()`);

	const { user } = vanillaStore.getState();

	// @TODO:
	return isString(user?.id);
};

export const clearAuth = (): void => {
	// console.info('[INFO] clearAuth()');

	// @TODO: Refactor/abstract this into a single store udpate...
	const {
		setUser,
		setUserType,
		setUserCompany,
		setRole,
		setCompany,
		setUserCompanies,
		setTokens,
	} = vanillaStore.getState();

	setUser(null);
	setUserType(null);
	setUserCompany(null);
	setRole(null);
	setCompany(null);
	setUserCompanies(null);
	setTokens(null);

	// #NOTE: Clear the currently set user
	setSentryUser(null);

	// #NOTE: Clear the freshworks widget user
	if (window.FreshworksWidget) {
		window.FreshworksWidget('logout');
	}
};

export const setAuth = (
	user?: UserWithAuthEmbedsWithoutCompanies,
	userType?: UserType,
	userCompany?: string,
	role?: PartialDeep<Role>,
	company?: PartialDeep<Company>,
	userCompanies?: Array<BaseUserCompanyWithAuthEmbeds>,
	tokens?: PartialDeep<UserTokens>,
	whitelistedJurisdictions?: Array<
		PartialDeep<JurisdictionCountry> | PartialDeep<JurisdictionCountryState>
	>,
	systemIsAdministratorRoles?: Array<PartialDeep<Role>>,
	whitelabelSettings?: Pick<
		CompanyWhitelabel,
		'isEnabled' | 'name' | '_rectangleLogoUrl' | '_squareLogoUrl'
	>
): void => {
	// console.info('[INFO] setAuth()', user, userCompany, userType, role, company, tokens, userCompanies);

	const {
		setUser,
		setUserType,
		setUserCompany,
		setRole,
		setCompany,
		setUserCompanies,
		setTokens,
		setWhitelistedJurisdictions,
		setSystemIsAdministratorRoles,
		setWhitelabelSettings,
	} = vanillaStore.getState();

	if (
		isNil(user) ||
		isNil(userType) ||
		isNil(userCompany) ||
		isNil(role) ||
		isNil(company) ||
		isNil(userCompanies) ||
		isNil(tokens) ||
		isNil(whitelistedJurisdictions) ||
		isNil(systemIsAdministratorRoles) ||
		isNil(whitelabelSettings)
	) {
		// @TODO: @Soul65 Should this thorw an error?
		// throwError('[ERROR] Missing some required set auth props', {
		// 	tags: {
		// 		service: 'AuthClientSideService',
		// 	},
		// 	extra: {
		// 		user: !isNil(user),
		// 		userCompany: !isNil(userCompany),
		// 		userType: !isNil(userType),
		// 		role: !isNil(role),
		// 		company: !isNil(company),
		// 		tokens: !isNil(tokens),
		// 		userCompanies: !isNil(userCompanies),
		// 		systemIsAdministratorRoles: !isNil(systemIsAdministratorRoles),
		// 	},
		// });

		return;
	}

	// @TODO: Refactor/abstract this into a single store udpate...
	setUser(user);
	setUserType(userType);
	setUserCompany(userCompany);
	setRole(role);
	setCompany(company);
	setUserCompanies(userCompanies);
	setTokens(tokens);
	setWhitelistedJurisdictions(whitelistedJurisdictions);
	setSystemIsAdministratorRoles(systemIsAdministratorRoles);
	setWhitelabelSettings(whitelabelSettings);

	// #NOTE: Identify the user:
	setSentryUser({
		id: user.id,
		email: user.email,
		userCompany,
	});
};

// @TODO:
// const _getCanonicalUrlToSign = (
//     method: RequestMethod,
//     urlParsed: URL,
// ): string =>  `${method.toUpperCase()  }\n${  // HTTPMethod
//     urlParsed.origin  }${urlParsed.pathname  }${urlParsed.hash  }\n${  // CanonicalUri
//     urlParsed.searchParams.toString()}`; // CanonicalQueryString

// @TODO:
// const _getSignature = (
// 	token: string,
// 	urlToSign: string,
// ) => createHmac('SHA256', Buffer.from(token, 'utf8')).update(urlToSign).digest('hex');

// @TODO:
// export const getPresignedUrl = (
// 	method: RequestMethod,
// 	url: string,
// 	// eslint-disable-next-line @typescript-eslint/default-param-last
// 	urlParams: BaseReturnableQueryParams = {},
// 	token: string,
// 	expires: number = 60 * 60 * 1000,
// ): Record<string, unknown> => {
// 	// console.info('[INFO Auth] getPresignedUrl()', method, url, token, expires);

// 	const { user, userCompany }: { user: UserWithAuthEmbedsWithoutCompanies | null, userCompany: string | null } = vanillaStore.getState();
// 	// console.debug('[DEBUG Auth] user:', { id: user?.id }, { userCompany });

// 	// @ts-ignore-error: @TODO: Fix this...
// 	const userId: string = user?.id;

// 	// Step 1a: Parse the given URL
//     const urlParsed = new URL(url);
//     // console.debug('[DEBUG] urlParsed:', urlParsed.origin, urlParsed.pathname);

//     // Step 1b: Add the authentication params & sort
//     urlParsed.searchParams.set('X-Indly-Algorithm', 'INDLY-HMAC1-SHA256');
//     urlParsed.searchParams.set('X-Indly-Credential', userId);
//     urlParsed.searchParams.set('X-Indly-Date', new Date().getTime().toString());
//     // urlParsed.searchParams.set('X-Indly-Date', '1683428149355');
//     urlParsed.searchParams.set('X-Indly-Expires', String(expires));
//     urlParsed.searchParams.set('X-Indly-SignedHeaders', '');
//     urlParsed.searchParams.sort();

//     // Step 1c: Generate the compiled canonical URL
//     const urlToSign = _getCanonicalUrlToSign(method, urlParsed);
//     // console.debug('[DEBUG] urlToSign:', urlToSign);

//     // Step 1d: Generate the signature
//     const signature = _getSignature(token, urlToSign);
//     urlParsed.searchParams.set('X-Indly-Signature', signature);
//     // console.debug('[DEBUG] signature:', signature);

// 	// Step 1e: Generate the full presigned URL
//     const presignedUrl: string = urlParsed.toString();
//     // console.debug('[DEBUG] presignedUrl:', presignedUrl);

// 	const urlParamsWithSignature = Object.fromEntries(new URLSearchParams(urlParsed.searchParams));
// 	// const urlParamsWithSignature = urlParsed.searchParams.entries().return();
// 	// console.debug('[DEBUG] urlParamsWithSignature:', urlParamsWithSignature);

//     return urlParamsWithSignature;
// }

export const hasPermission = (...abilityTuples: AppAbilities[]): boolean => {
	// console.info('[INFO] hasPermission()', abilityTuples);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { ability } = vanillaStore.getState();
	// console.debug('[DEBUG] ability:', ability);

	if (isNil(ability)) {
		return false;
	}

	// eslint-disable-next-line arrow-body-style
	const canAbility: boolean = abilityTuples.every((abilityTuple) => {
		// console.debug('[DEBUG] IN map()', abilityTuple[0], abilityTuple[1]);

		// @ts-ignore
		return ability.can(abilityTuple[0], abilityTuple[1]);
	});
	// console.debug('[DEBUG] canAbility:', canAbility);

	// if (!canAbility) {
	// 	const { role } = vanillaStore.getState();

	// 	// @ts-ignore
	// 	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	// 	console.warn(`[WARN] No role permission to ability (role: ${role?.id}, label: ${role?.label})`, abilityTuples);
	// }

	return canAbility;
};

export const hasOneOfPermission = (
	abilityTuples: [AppAbilityActions[], AppAbilitySubjects][]
): boolean => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { ability } = vanillaStore.getState();

	if (isNil(ability)) {
		return false;
	}

	const canAbility: boolean = abilityTuples[0].some((abilityTuple) =>
		// @ts-ignore
		ability.can(abilityTuple, abilityTuples[1])
	);

	// const canAbility: boolean = abilityTuples.some((abilityTuple) => {
	// 	// @ts-ignore
	// 	return ability.can(abilityTuple[0], abilityTuple[1]);
	// });

	return canAbility;
};

export const isCompanyIndependently = (): boolean => {
	const { company }: { company: PartialDeep<Company> | null } = vanillaStore.getState();

	return _isCompanyIndependently(company);
};

export const isRoleIndependentlySuperAdmin = (): boolean => {
	const { role }: { role: PartialDeep<Role> | null } = vanillaStore.getState();

	return _isRoleIndependentlySuperAdmin(role);
};

// eslint-disable-next-line class-methods-use-this
export const getDefaultRoute = (): string | undefined => {
	// console.info('[INFO] getDefaultRoute()');
	// const debugging = (new Error()).stack;
	// console.debug(debugging);

	const { user, userType } = vanillaStore.getState();
	// console.debug('[DEBUG] getDefaultRoute:', { user, userType });

	// Not logged in
	// @TODO:
	if (isNil(user) || !isString(user?.id)) {
		return LOGIN_PAGE_PATH;
	}

	if (userType === UserType.ADMINISTRATOR && hasPermission([WorkerScopes.Read, 'Worker'])) {
		return '/engagements';
	}

	if (userType === UserType.ADMINISTRATOR && hasPermission([FileScopes.Read, 'File'])) {
		return '/files';
	}

	if (userType === UserType.ADMINISTRATOR && hasPermission([VendorScopes.Read, 'Vendor'])) {
		return '/vendors';
	}

	if (userType === UserType.ADMINISTRATOR && hasPermission([ClientScopes.Read, 'Client'])) {
		return '/clients';
	}

	if (userType === UserType.WORKER && !user._isSetup) {
		const nonArchivedEngagements = filter(user.engagements, (el) => el._archived !== true);

		const mostRecentEngagement = maxBy(nonArchivedEngagements, (el) => el._createdDate);

		if (mostRecentEngagement) return `/onboarding/${mostRecentEngagement.id}`;
		else return '/profile/actionItems';
	}

	if (userType === UserType.WORKER) {
		return '/profile/actionItems';
	}

	return undefined;
};

export const isWorkerFieldReadOnly = (
	configuration?: FieldStateList | `${FieldStateList}`
): boolean => {
	const { userType } = vanillaStore.getState();

	return userType === UserType.WORKER && configuration === FieldStateList.VISIBLE;
};

export const isFieldEditable = (configuration?: FieldStateList | `${FieldStateList}`): boolean => {
	const { userType } = vanillaStore.getState();

	return userType === UserType.ADMINISTRATOR || configuration === FieldStateList.EDITABLE;
};

export const isFieldHidden = (
	configuration?: FieldStateList | `${FieldStateList}`,
	featureEnabled?: boolean
): boolean => {
	const { userType } = vanillaStore.getState();

	return (
		userType !== UserType.ADMINISTRATOR &&
		(configuration === FieldStateList.HIDDEN || featureEnabled === false)
	);
};

export const isWorkerEmailEditable = (): boolean => {
	const { userType, company } = vanillaStore.getState();

	if (userType === UserType.WORKER && company?.settings?.isEmailEditableForWorker) {
		return true;
	}

	if (userType === UserType.ADMINISTRATOR) {
		return true;
	}

	return false;
};
