const MainLoading = () => (
	<div id='main-loading'>
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
		<div />
	</div>
);

export default MainLoading;
