const HeadFavicons = () => (
	<>
		<link rel='apple-touch-icon' sizes='180x180' href='/icons/apple-touch-icon.png?v=4' />
		<link rel='icon' type='image/png' sizes='32x32' href='favicon-32x32.png?v=4' />
		<link
			rel='icon'
			type='image/png'
			sizes='192x192'
			href='/icons/android-chrome-192x192.png?v=4'
		/>
		<link rel='icon' type='image/png' sizes='16x16' href='favicon-16x16.png?v=4' />
		<link rel='manifest' href='site.webmanifest?v=4' />
		<link rel='mask-icon' href='/icons/safari-pinned-tab.svg?v=4' color='#420039' />
		<link rel='shortcut icon' href='favicon.ico?v=4' />
		<meta name='apple-mobile-web-app-title' content='Independently' />
		<meta name='application-name' content='Independently' />
		<meta name='msapplication-TileColor' content='#420039' />
		<meta name='msapplication-TileImage' content='/icons/mstile-144x144.png?v=4' />
		<meta name='msapplication-config' content='/icons/browserconfig.xml?v=4' />
		<meta name='theme-color' content='#420039' />
	</>
);

export default HeadFavicons;
