/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-console */
import { GlobalStyles } from 'assets/styles/global.style';
import { setAuth } from 'auth/auth-client-side.service';
import getServerSideProps from 'auth/auth-get-server-side-props.service';
import MainLoading from 'components/MainLoading.component';
import { pick } from 'lodash';
import dynamic from 'next/dynamic';
import { ReactElement } from 'react';
import Head from '../components/head/Head.component';
import type { ClientSideProps, ServerSideProps } from '../types/next.type';

// @TODO: TEMP FOR DEBUGGING
// eslint-disable-next-line import/order
// import { Box } from '@mui/material';

/**
 * Import the client-side index page without server-side-rendering
 *
 * @param pageProps ClientSideProps
 * @returns ReactElement
 */
const ClientSideIndex = dynamic(() => import('../main/ClientMain.main'), {
	ssr: false,
});

/**
 * Initializes mandatory server-side auth middleware
 *  - If logged in, returns type User{} & Companies[] in ServerSideProps => const Index = (props: ServerSideProps): ReactElement
 *  - If logged out, redirect (server-side) to login page
 *    - OPTIONALLY, if 401 and TRY_REFRESH_TOKEN returned, pass flag to client-side to attempt sessionRefresh (without yet redirecting)
 *
 * @param context GetServerSidePropsContext
 * @returns Promise<GetServerSidePropsResult<ServerSideProps>>
 */
export { getServerSideProps };

// Intended to ONLY run client-side (as a SPA - Single Page Application) in React 18+ / NextJS 12+ ala "SPA Mode"
const Index = (pageProps: ServerSideProps): ReactElement => {
//////////////////////////////////////
//////////////
//////////////////////////////////////////////////////////////////////////////////////////
///////////
///
//////////////////////////////////////////
/////////////////////////////
/////////////////////////////////////////////////
/////////////////////////////////
///////////////////////////////////////////////////////
////
///////////

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const clientSidePageProps: ClientSideProps = pick(pageProps, [
		'isWhyDidYouRenderEnabled',
		'fromSupertokens',
	]);

	const {
		user,
		userType,
		userCompany,
		role,
		company,
		userCompanies,
		tokens,
		whitelistedJurisdictions,
		systemIsAdministratorRoles,
		whitelabelSettings,
	} = pageProps;

	setAuth(
		user,
		userType,
		userCompany,
		role,
		company,
		userCompanies,
		tokens,
		whitelistedJurisdictions,
		systemIsAdministratorRoles,
		whitelabelSettings
	);
	// console.debug('[DEBUG] post-setAuth()');

	return (
		<>
			<Head />
			<GlobalStyles />
			<MainLoading />
			<ClientSideIndex />
		</>
	);
};

export default Index;

/*

			<Box
				sx={{
					height: '100%',
					width: '100%',
					position: 'absolute',
					zIndex: 2,
				}}
			>[HELLO WORLD 7!]</Box>
	

*/
