import { isNil } from "remeda";
import { CompanyType } from "../../../dictionaries/company-types";
import { DbDocumentsMap } from "../../../shared/database/documents-map.database";
export const INDEPENDENTLY_COMPANY_ID = DbDocumentsMap.companies.independentlyCompany;
export const INDEPENDENTLY_SUPER_ADMIN_ROLE_ID = DbDocumentsMap.roles.systemIndependentlySuperAdminRole;
export const isCompanyIndependently = (company)=>{
    return !isNil(company) && company?.id === INDEPENDENTLY_COMPANY_ID && company?._system === true && company?._companyType === CompanyType.INTERNAL;
};
export const isRoleIndependentlySuperAdmin = (role)=>{
    return !isNil(role) && role?.id === INDEPENDENTLY_SUPER_ADMIN_ROLE_ID && role?._system === true && role?._isIndlySuperAdmin === true && role?._allowAccessToAllCompanyTenants === true;
};
