import { mainTheme } from '@indly/themes/main.theme';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    html,
    body,
    body > div:first-child,
    div#__next,
    div#__next > div {
        height: 100%;
		overflow: hidden;
    }

    body {
        margin: 0;
    }

    #main-loading {
        position: absolute;
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: ${mainTheme.palette.blue.light};
        z-index: 1;
    }

    @keyframes building-blocks {
		0%,
		20% {
			opacity: 0;
			transform: translateY(-300%);
		}

		30%,
		70% {
			opacity: 1;
			transform: translateY(0);
		}

		90%,
		100% {
			opacity: 0;
			transform: translateY(300%);
		}
	}

	#main-loading div {
		height: 20px;
		position: absolute;
		width: 20px;
	}
	#main-loading div:after {
		animation: building-blocks 2.1s ease infinite backwards;
		background: ${mainTheme.palette.white.main};
		content: '';
		display: block;
		height: 20px;
		width: 20px;
	}
	#main-loading div:nth-child(1) {
		transform: translate(-50%, -50%) translate(60%, 120%);
	}
	#main-loading div:nth-child(2) {
		transform: translate(-50%, -50%) translate(-60%, 120%);
	}
	#main-loading div:nth-child(3) {
		transform: translate(-50%, -50%) translate(120%, 0);
	}
	#main-loading div:nth-child(4) {
		transform: translate(-50%, -50%);
	}
	#main-loading div:nth-child(5) {
		transform: translate(-50%, -50%) translate(-120%, 0);
	}
	#main-loading div:nth-child(6) {
		transform: translate(-50%, -50%) translate(60%, -120%);
	}
	#main-loading div:nth-child(7) {
		transform: translate(-50%, -50%) translate(-60%, -120%);
	}
	#main-loading div:nth-child(1):after {
		animation-delay: 0.15s;
	}
	#main-loading div:nth-child(2):after {
		animation-delay: 0.3s;
	}
	#main-loading div:nth-child(3):after {
		animation-delay: 0.45s;
	}
	#main-loading div:nth-child(4):after {
		animation-delay: 0.6s;
	}
	#main-loading div:nth-child(5):after {
		animation-delay: 0.75s;
	}
	#main-loading div:nth-child(6):after {
		animation-delay: 0.9s;
	}
	#main-loading div:nth-child(7):after {
		animation-delay: 1.05s;
	}

    .daterange-picker {
        display: table
    }

    .MuiPopover-root .MuiBackdrop-root {
        background-color: transparent !important
    }

    .simplebar-scrollbar::before {
        background-color: ${mainTheme.palette.purple.main}
    }

	.MuiTooltip-popper {
		white-space: pre-line;
	}
`;
