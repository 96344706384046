import i18n from 'libs/localization/i18n-instance.service';
import NextHead from 'next/head';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import HeadFavicons from './HeadFavicons.component';

const Head = (): ReactElement => {
	const { t } = useTranslation('app', { i18n });
	console.debug('[DEBUG] in-app Head.component()...');

	return (
		<NextHead>
			<title>{t('head_title')}</title>
			<meta charSet='utf-8' />
			<meta name='viewport' content='initial-scale=1.0, width=device-width' />
			<HeadFavicons />
		</NextHead>
	);
};

export default Head;
