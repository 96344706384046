// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';
import type { UnionToArray } from '../libs/types/union-to-array.type';

export enum UserType {
	ADMINISTRATOR = 'administrator',
	WORKER = 'worker',
}

export type UserTypeCount = UnionToArray<keyof typeof UserType>['length'];

export enum UserTypeModels {
	Administrator = 'Administrator',
	Worker = 'Worker',
}

export interface UserTypeItem extends BaseDictionary {
	code: UserType;
	id: `${IdNamespace.UserType}_${string}`;
	order: number;
	icon: string;
	label: string;
	collection: string;
	repository: string;
	defaultPage: string;
}

export const userTypes = Map<UserType | `${UserType}`, UserTypeItem>({
	[UserType.ADMINISTRATOR]: {
		code: UserType.ADMINISTRATOR,
		id: `usrty_2FeQfh9ZRIPeQZIyVotVPQx71k2`,
		order: 0,
		icon: 'user-gear',
		label: 'Administrator',
		collection: 'users',
		repository: 'User',
		defaultPage: '/engagements',
	},
	[UserType.WORKER]: {
		code: UserType.WORKER,
		id: `usrty_2FeQfjI3MdZnYY009Nir2jVs6w5`,
		order: 1,
		icon: 'circle-user',
		label: 'Worker',
		collection: 'users',
		repository: 'User',
		defaultPage: '/profile/actionItems',
	},
});
